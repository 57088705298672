import React, { useEffect, useState, ComponentProps } from "react";
import { Section } from "./section";
import { format, parseISO } from "date-fns";
import classNames from "classnames";

export interface Event {
  id: number;
  displayName: string;
  type: string;
  uri: string;
  status: "ok" | "postponed" | "cancelled";
  popularity: number;
  start: Start;
  performance: Performance[];
  ageRestriction: null;
  flaggedAsEnded: boolean;
  venue: Venue;
  location: Location;
}

export interface Location {
  city: string;
  lat: number;
  lng: number;
}

export interface Performance {
  id: number;
  displayName: string;
  billing: string;
  billingIndex: number;
  artist: MetroArea;
}

export interface MetroArea {
  id: number;
  displayName: string;
  uri: string;
  identifier?: any[];
  country?: Country;
}

export interface Country {
  displayName: string;
}

export interface Start {
  date: Date;
  datetime: string;
  time: string;
}

export interface Venue {
  id: number;
  displayName: string;
  uri: string;
  metroArea: MetroArea;
  lat: number;
  lng: number;
}

const Button: React.FC<{ className?: string } & ComponentProps<"a">> = ({
  className,
  children,
  ...props
}) => (
  <a
    {...props}
    className={classNames(
      className,
      "rounded border-background-dark border text-background-dark px-3 py-1 hover:text-white hover:bg-background-dark transition-colors duration-200 group-hover:bg-background-dark group-hover:text-white"
    )}
  >
    {children}
  </a>
);

export const Songkick: React.FC = () => {
  const [concerts, setConcerts] = useState<Event[]>();

  useEffect(() => {
    fetch("/concerts.json")
      .then((r) => r.json())
      .then((r) => setConcerts(r.resultsPage.results.event));
  }, []);

  return (
    <Section animate={concerts ? "show" : "hidden"}>
      <header className="flex items-center justify-between px-5 pt-4 mb-4">
        <h2 className="text-xl">Tour Dates</h2>
        <Button href="https://www.songkick.com/artists/10099885-small-strides">
          Songkick
        </Button>
      </header>

      {concerts?.length === 0 && (
        <div className="px-5 py-4 border-t border-white border-opacity-20">
          No upcoming tour dates. Subscribe on Songkick to be informed of new
          concerts 🚀
        </div>
      )}

      <ul>
        {concerts?.map((c) => (
          <li key={c.id} className={c.status !== "ok" ? "opacity-50" : ""}>
            <a
              href={c.uri}
              className="flex items-center px-5 py-3 transition-colors duration-200 border-t border-white border-opacity-20 group hover:bg-white hover:bg-opacity-10"
            >
              <div className="flex flex-col items-center justify-center w-12 h-12 mr-4 text-center transition-colors duration-200 bg-gray-500 bg-opacity-75 group-hover:bg-opacity-100 rounded">
                <div className="text-lg font-bold leading-none">
                  {format(parseISO(c.start.datetime ?? c.start.date), "d")}
                </div>
                <div className="mt-1 text-xs leading-none uppercase">
                  {format(parseISO(c.start.datetime ?? c.start.date), "LLL")}
                </div>
              </div>
              <div>
                <div className="font-bold uppercase">
                  {c.venue.displayName}
                  {c.status === "cancelled" && " (cancelled)"}
                  {c.status === "postponed" && " (postponed)"}
                </div>
                <div className="text-sm opacity-75">
                  {c.venue.metroArea.displayName},{" "}
                  {c.venue.metroArea.country?.displayName}
                </div>
              </div>
              <Button className="ml-auto">Tickets</Button>
            </a>
          </li>
        ))}
      </ul>
    </Section>
  );
};
