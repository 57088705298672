import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { motion } from "framer-motion";

import "../css/style.css";

import { Songkick } from "../components/songkick";
import { Social } from "../components/social";
import { Section } from "../components/section";
import { Lineup } from "../components/lineup";
import { Link } from "gatsby";
import { Logo } from "../components/logo";
import { TgbofBackground } from "../components/tgbof-bg";

const VideoSection: React.FC<{ url: string; ratio?: string }> = ({
  url,
  ratio = "56.25%",
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 1000);
  }, [setIsLoaded]);

  return (
    <Section animate={isLoaded ? "show" : "hidden"}>
      <div
        style={{
          position: "relative",
          height: 0,
          overflow: "hidden",
          maxWidth: "100%",
          paddingBottom: ratio,
        }}
      >
        <iframe
          src={url}
          onLoad={() => setIsLoaded(true)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default () => (
  <>
    <Helmet>
      <title>Small Strides</title>
    </Helmet>

    <motion.div
      animate="show"
      initial="hidden"
      variants={{
        show: {
          transition: {
            staggerChildren: 0.1,
          },
        },
      }}
      className="container max-w-3xl px-4 pt-10 pb-10 mx-auto lg:pt-40"
    >
      <Logo />
      <Social />

      <VideoSection
        url="https://www.youtube.com/embed/videoseries?list=PLcxowIvVub-uyigAKVD7w0rm2e_xvX_5j"
        ratio="56.25%"
      />

      <Section title="About Us" className="px-5 py-4">
        <article>
          <h2 className="mb-2 text-xl">About Us</h2>
          <p className="mb-1">
            Departure, stagnation, hope and anxiety – these are the topics that
            indie-rock band Small Strides deals with while uniting elements of
            alternative rock, electro, pop, and emo in their music. Death Cab
            for Cutie's stylistic bandwidth, Coldplay's hymnic relatability, as
            well as Radiohead's love for experimentation are forming important
            cornerstones of Small Strides' sound. Even in their live shows, they
            take it a step further and realize their vision with live-looping as
            well as their very own samples and synthesizers. With every song,
            they rediscover new facets of themselves, constantly facing the
            decision of where to go next.
          </p>
          <p className="mb-1">
            With "The Ground Beneath Our Feet", Small Strides present a debut
            album that represents a sound cosmos of its own in the band's
            history. Self-produced, it marks a transition into a new era,
            characterised by the expansion of their sound to include elements of
            ambient, post-rock and progressive and consistently building on the
            strengths of their EP "Crossings". Lyrically, they focus on the
            conflicts we face between one another, how we deal with isolation
            and the desperate clinging to failed relationships. Yet there is
            also room for the positive, such as finding one's way to oneself or
            finding support in unsettling times.
          </p>
          <p className="mb-1">
            For Small Strides, "The Ground Beneath Our Feet" is an important
            milestone on their journey, which could lead in any direction.
          </p>
        </article>
      </Section>

      <Lineup />

      <Songkick />
    </motion.div>

    <footer
      className="max-w-6xl mx-auto text-white"
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)",
      }}
    >
      <div className="flex items-center justify-between max-w-3xl px-4 py-4 mx-auto text-shadow">
        <div>&copy; Small Strides {new Date().getFullYear()}</div>
        <div>
          <Link to="disclaimer" className="mr-4">
            Disclaimer
          </Link>
          <a href="https://drive.google.com/drive/folders/1V1E6Lmt35voTOpOAUaUQsh91mJSap_58?usp=sharing">
            Press Kit
          </a>
        </div>
      </div>
    </footer>

    <TgbofBackground />
  </>
);
